* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.table_class {
  border-bottom: none !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1px !important;
  background-color: #f8f9fa !important;
  letter-spacing: 0.5px !important;
  color: #2f3746 !important;
  text-transform: uppercase !important;
  white-space: nowrap;
}
body {
  margin: 0 !important;
  color: #cbccd2 !important;
  font-size: 14px !important;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-color: #070c27 !important;
}
.main-order-table {
  background-color: #111633 !important;
  color: #cbccd2 !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  background-image: none !important;
  padding: 20px 20px !important;
  box-shadow: 0px 0px 2px #6a7199 !important;
  height: auto;
}
.modal-order-table {
  background-color: #111633 !important;
  color: #cbccd2 !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 10px !important;
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  background-image: none !important;
  padding: 20px 20px !important;
  box-shadow: 0px 0px 2px #6a7199 !important;
  width: 100%;
  margin: auto;
  height: 100%;
  overflow: scroll !important;
}
/* Update the CSS for table rows */
.table-row {
  background-color: transparent;
}

/* Add this CSS to your component's CSS file or a global CSS file */
.glass-morphism {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 10px !important;
  color: #cbccd2 !important;
  box-shadow: 0px 0px 10px rgba(140, 124, 240, 0.2) !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.glass-morphism:hover {
  box-shadow: 0px 0px 20px rgba(140, 124, 240, 0.4) !important;
}
.table-heading-and-data {
  white-space: nowrap;
  color: white !important;
}
.modal-table {
  width: 100%;
  margin: auto;
  height: 80%;
  overflow: scroll;
}
.modal-table::-webkit-scrollbar {
  display: none;
}
.product-table-text {
  margin: 0px !important;
  font-size: 14px !important;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji" !important;
  font-weight: 400 !important;
  line-height: 1.43 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  color: rgba(203, 204, 210, 0.7) !important;
}
/* .table-scroll, */
.modal-order-table::-webkit-scrollbar {
  /* width: 10px; */
  display: none;
}
.table-scroll::-webkit-scrollbar {
  /* width: 10px; */
  display: none;
}

/* .table-scroll::-webkit-scrollbar-track {
  background-color: #fff;
}

.table-scroll::-webkit-scrollbar-thumb {
  background-color: #bc2423;
  border-radius: 10px;
} */
.image-upload-customize {
  border: 2px dashed #fff;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;
}
